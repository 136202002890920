.top-bar {
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 30px;
    display: flex;
    color: white;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black-1b);

    .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center !important;

        .message {
            opacity: 0;
            height: 100%;
            width: 400px;
            display: flex;
            cursor: pointer;
            position: absolute;
            visibility: hidden;
            text-wrap: nowrap;
            align-items: center;
            letter-spacing: .8px;
            min-width: max-content;
            justify-content: center;
            color: var(--inactive-color);
            transition: var(--transition);
            font: normal 11px var(--font-hand);

            &:hover {
                color: var(--color-white-ff);
            }
        }

        .message.show {
            opacity: 1;
            visibility: visible;
        }

        a {
            text-decoration: none;
            color: var(--inactive-color);

            &:hover {
                text-decoration: underline;
                color: var(--color-white);
            }
        }

        &:nth-child(1) {
            justify-content: flex-start;
        }

        &:nth-child(2) {
            justify-content: center;
        }

        &:nth-child(3) {
            justify-content: flex-end;
        }
    }
}

.header {
    top: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    // background-color: light-dark(var(--color-white-ff), var(--color-black-20));
    background-color: var(--aesthetic-secondary-background);

    .flex {
        gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;

        .nav-menu {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .nav-list {
                // gap: 14px;
                margin: 0px;
                padding: 0px;
                height: 100%;
                display: flex;
                list-style: none;
                align-items: center;

                .line {
                    width: 1px;
                    height: 100%;
                    background-color: var(--border-color);
                }

                .nav-item {
                    height: 100%;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: center;

                    .nav-wrapper {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        .nav-link {
                            gap: 5px;
                            height: 100%;
                            display: flex;
                            margin-right: 20px;
                            align-items: center;
                            letter-spacing: 1px;
                            text-transform: capitalize;
                            color: light-dark(black, white);
                            font: normal 14px var(--font-hand);
                            color: var(--aesthetic-primary-text);
                        }
                    }

                    .nav-sub-list {
                        left: 0;
                        top: 100%;
                        margin: 0;
                        padding: 0;
                        opacity: 0;
                        display: flex;
                        list-style: none;
                        position: absolute;
                        visibility: hidden;
                        flex-direction: column;
                        transition: var(--transition);
                        border: 1px solid var(--border-color);
                        background-color: light-dark(var(--color-white-ff), var(--color-black-20));

                        li {
                            width: 120px;
                            min-width: 120px;
                            height: 40px;
                            display: flex;
                            padding: 0px 10px;
                            align-items: center;
                            justify-content: center;
                            border-bottom: 1px solid var(--border-color);

                            a {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                letter-spacing: 1px;
                                justify-content: space-between;
                                font: normal 12px var(--font-hand);
                                color: var(--aesthetic-secondary-text);
                                // background-color: rosybrown;

                                span {
                                    font: normal 12px var(--font-mono-code);
                                }
                            }

                            &:last-child {
                                border: none;
                            }
                        }

                        &.show {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    &:hover {

                        .nav-sub-list {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    &:first-child {
                        display: none;
                    }
                }
            }
        }

        &:nth-child(1) {
            justify-content: flex-start;
        }

        &:nth-child(2) {
            justify-content: center;
        }

        &:nth-child(3) {
            justify-content: flex-end;
        }
    }
}

.menu {
    top: 0;
    left: -340px;
    // right: -340px;
    z-index: 12;
    width: 300px;
    height: 100vh;
    display: flex;
    position: fixed;
    flex-direction: column;
    transition: var(--transition);
    border-right: 1px solid var(--border-color);
    // background-color: light-dark(var(--color-white-ff), var(--color-black-20));
    background-color: var(--aesthetic-secondary-background);

    .menu-header {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        letter-spacing: .8px;
        font: bold 14px var(--font-hand);
        border-bottom: 1px solid var(--border-color);

        span {
            width: 100%;
            padding: 0px 50px;
            justify-content: space-between;
        }
    }

    .menu-content {
        width: 100%;
        height: calc(100vh - 80px);
        display: flex;
        overflow-x: auto;
        align-items: flex-start;
        justify-content: center;

        .nav-menu {
            width: 100%;
            display: flex;

            .nav-list {
                margin: 0;
                padding: 0;
                width: 100%;
                display: flex;
                list-style: none;
                align-items: center;
                flex-direction: column;

                .nav-item {
                    width: 100%;
                    display: flex;
                    min-height: 60px;
                    position: relative;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    border-bottom: 1px solid var(--border-color);

                    .nav-wrapper {
                        width: 100%;
                        display: flex;
                        position: relative;
                        align-items: center;
                        justify-content: space-between;

                        .nav-link {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            cursor: pointer;
                            min-height: 60px;
                            max-height: 60px;
                            overflow: hidden;
                            text-indent: 50px;
                            user-select: none;
                            text-wrap: nowrap;
                            align-items: center;
                            text-decoration: none;
                            justify-content: space-between;
                            font: normal 14px var(--font-hand);
                            color: var(--aesthetic-tertiary-text);

                            &:hover,
                            &:focus-visible {
                                outline: none;
                                text-decoration: underline;
                            }

                        }

                        .icon-button {
                            right: 50px;
                            position: absolute;
                            color: var(--aesthetic-tertiary-text);

                            &:focus-visible {
                                outline: none;
                                border: 1px dashed var(--border-color);
                            }
                        }
                    }

                    .nav-sub-list {
                        top: 100px;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        display: none;
                        list-style: none;
                        flex-direction: column;
                        transition: var(--transition);

                        .nav-sub-item {
                            width: 100%;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // border-bottom: 1px solid var(--border-color);

                            .nav-sub-link {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                text-indent: calc(50px + 15px);
                                text-indent: 50px;
                                font: normal 14px var(--font-hand);
                                // font-family: var(--font-space-mono);
                                color: var(--aesthetic-secondary-text);

                                .nav-sub-count {
                                    right: calc(50px - 0px);
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    min-width: 30px;
                                    min-height: 30px;
                                    max-width: 30px;
                                    max-height: 30px;
                                    text-indent: 0px;
                                    overflow: hidden;
                                    text-align: center;
                                    position: absolute;
                                    align-items: center;
                                    justify-content: center;
                                    font: normal 12px var(--font-mono-code);
                                    font-family: var(--font-space-mono);
                                }

                                &:hover,
                                &:focus-visible {
                                    outline: none;
                                    text-decoration: underline;

                                    .nav-sub-count {
                                        text-decoration: underline;
                                    }
                                }
                            }

                            &:first-child {
                                border-top: 1px solid var(--border-color);
                            }

                            &:last-child {
                                border-bottom: none;
                            }
                        }

                        &.show {
                            display: flex;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    &.show {
        left: 0;
        // right: 0;
    }
}

.bag {
    top: 0px;
    right: -380px;
    z-index: 12;
    width: 320px;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
    transition: var(--transition);
    border-left: 1px solid var(--border-color);
    background-color: light-dark(var(--color-white-ff), var(--color-black-20));

    .bag-header {
        width: calc(100% - 0px); // 40px
        height: 80px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        justify-content: center;
        font: bold 14px var(--font-hand);
        border-bottom: 1px solid var(--border-color);

        span {
            min-width: max-content;
        }
    }

    .bag-content {
        width: 100%;
        height: calc(100vh - 80px);
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        .bag-main {
            width: 100%;
            height: calc(100vh - 80px - 138px);
            display: flex;
            overflow-y: auto;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;

            .promo {
                top: 0;
                gap: 10px;
                z-index: 2;
                width: 100%;
                display: flex;
                position: sticky;
                padding: 10px 0px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                // background-color: var(--color-white-ff);
                border-bottom: 1px solid var(--border-color);

                .promo-text {
                    width: calc(100% - 40px);
                    text-align: center;
                    text-wrap: balance;
                    letter-spacing: 1px;
                    font: normal 12px var(--font-hand);
                    // background-color: rosybrown;
                }

                input {
                    margin: 0;
                    padding: 0;
                    // margin: 10px 0px;
                    width: calc(100% - 40px);
                    accent-color: var(--primary-color);
                    color: light-dark(var(--color-white-fa), var(--color-black-18));
                }
            }
        }

        .product-item {
            width: 100%;
            display: flex;
            overflow: hidden;
            min-height: 90px;
            padding: 10px 0px;
            align-items: center;
            flex-direction: column;
            border-bottom: 1px solid var(--border-color);

            .product-item-wrapper {
                display: flex;
                align-items: flex-start;
                width: calc(100% - 40px);
                justify-content: flex-start;

                .product-item-picture {
                    width: 90px;
                    height: 90px;
                    display: flex;
                    margin-right: 14px;
                    // border-radius: 8px;
                    align-items: center;
                    justify-content: center;
                    border: 1px dashed var(--border-color);
                    background-color: light-dark(var(--color-white-fa), var(--color-black-18));

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        user-select: none;
                        pointer-events: none;
                        object-position: center;
                        filter: drop-shadow(2px 2px 4px #4d4d4d);
                    }
                }

                .product-item-details {
                    gap: 8px;
                    width: calc(100% - 90px - 14px);
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .product-item-title {
                        width: 100%;
                        overflow: hidden;
                        text-wrap: nowrap;
                        letter-spacing: 1;
                        text-overflow: ellipsis;
                        font: normal 14px var(--font-hand);
                    }

                    .product-item-price {
                        display: flex;
                        flex-wrap: nowrap;
                        text-transform: uppercase;
                        font: normal 12px var(--font-mono-code);
                    }

                    .product-item-control {
                        gap: 4px;
                        display: flex;
                        margin-top: 10px;
                        align-items: center;
                        justify-content: flex-start;

                        .icon-button {
                            border-color: var(--border-color);

                            &:last-child {
                                margin-left: 20px;

                                &:hover, &:active {
                                    color: firebrick;
                                }
                            }
                        }

                        .screen {
                            width: 28px;
                            height: 28px;
                            display: flex;
                            cursor: default;
                            overflow: hidden;
                            // border-radius: 4px;
                            align-items: center;
                            box-sizing: content-box;
                            justify-content: center;
                            border: 1px solid var(--border-color);
                            font: normal 12px var(--font-mono-code);
                        }
                    }
                }
            }
        }

        .bag-control {
            bottom: 0;
            width: 100%;
            display: flex;
            padding: 30px 0px;
            position: absolute;
            align-items: center;
            justify-content: center;
            border-top: 1px solid var(--border-color);
            background-color: light-dark(var(--color-white-ff), var(--color-black-20));

            .wrapper {
                flex-direction: column;
                width: calc(100% - 40px);
            }

            .subtotal {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                justify-content: space-between;
                font: bold 14px var(--font-mono-code);
            }

            .primary-button {
                width: 100%;
                justify-content: space-evenly;
                color: light-dark(white, black);
                background-color: light-dark(var(--color-black-20), var(--color-white-ff));
            }
        }
    }

    &.show {
        right: 0px;
    }
}

.main {
    gap: 40px;
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
    padding-top: calc(30px + 80px + 40px);
    padding-bottom: 100px;

    .banner {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        margin-top: -40px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(/public/banner-tour-i.png);
    }

    .section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .container {
            align-items: center;
            flex-direction: column;
        }

        .media-block {
            width: 100%;
            height: 92vh;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            transition: var(--transition);

            a {
                inset: 0;
                z-index: 3;
                opacity: 0;
                position: absolute;
            }

            picture {
                z-index: 1;
                inset: 0px;
                position: absolute;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    user-select: none;
                    pointer-events: none;
                    object-position: center;
                }
            }

            h2 {
                z-index: 2;
                user-select: none;
                font: normal 30px var(--font-hand);
            }

            &:hover {
                filter: brightness(0.4);
            }
        }
    }
}

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-bottom: 1px solid var(--border-color);
    // background-color: light-dark(var(--color-white-ff), var(--color-black-20));

    .container {
        padding: 40px 0px;
        flex-direction: column;
        border-top: 1px solid var(--border-color);

        .newsletter {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .newsletters-form {
                width: 500px;
                display: flex;
                row-gap: 10px;
                align-items: center;
                padding-bottom: 32px;
                flex-direction: column;

                .form-block {
                    width: 100%;
                    display: flex;
                    column-gap: 10px;
                    align-items: center;
                    flex-direction: row;
                    justify-content: center;
                }

                #block-3,
                #block-5 {
                    margin-bottom: -10px;
                }

                .title {
                    user-select: none;
                    text-align: center;
                    text-wrap: balance;
                    margin-bottom: 14px;
                    pointer-events: none;
                    letter-spacing: .8px;
                    font: normal 22px var(--font-hand);
                }

                .input,
                select {
                    width: 100%;
                    height: 34px;
                    appearance: none;
                    user-select: none;
                    // border-radius: 4px;
                    padding-left: 16px;
                    letter-spacing: .8px;
                    transition: var(--transition);
                    font: normal 14px var(--font-hand);
                    border: 1px solid var(--border-color);
                    background-color: light-dark(var(--color-white-fa), var(--color-black-18));

                    &:focus-visible {
                        outline: none;
                        border-color: var(--primary-color);
                        box-shadow: 0px 0px 8px var(--primary-color);
                    }

                    &:hover {
                        border-color: light-dark(var(--color-black-20), var(--color-white-99));
                    }
                }

                .primary-button {
                    background-color: var(--color-black-12) !important;
                }

                .policy {
                    width: 100%;
                    user-select: none;
                    text-align: center;
                    text-wrap: balance;
                    letter-spacing: .8px;
                    font: normal 11px var(--font-hand);

                    a {
                        text-decoration: underline;
                    }
                }

                .form-alert {
                    color: white;
                    padding: 1px 8px;
                    border-radius: 4px;
                    min-width: max-content;
                    background-color: firebrick;
                    font: normal 11px var(--font-hand);
                }

                .alert {
                    width: 100%;
                    height: 18px;
                    display: flex;
                    color: white;
                    cursor: default;
                    overflow: hidden;
                    text-indent: 10px;
                    user-select: none;
                    position: relative;
                    border-radius: 6px;
                    align-items: center;
                    margin-bottom: 10px;
                    letter-spacing: .4px;
                    text-overflow: ellipsis;
                    justify-content: center;
                    line-height: 18px !important;
                    background-color: firebrick;
                    font: normal 10px var(--font-hand);
                }
            }
        }

        .copyright {
            gap: 4px;
            width: 100%;
            display: flex;
            padding-top: 30px;
            align-items: center;
            justify-content: center;
            font: normal 10px var(--font-hand);
            letter-spacing: 1px;
        }
    }
}

.login {
    top: 200px;
    opacity: 0;
    z-index: 20;
    width: 400px;
    display: flex;
    position: fixed;
    visibility: hidden;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    transition: var(--transition);
    border: 1px solid var(--border-color);
    background-color: light-dark(var(--color-white-ff), var(--color-black-20));

    .form {
        padding: 20px 0px;
        width: calc(100% - 40px);

        .block {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            .title {
                margin: 10px 0px;
                font: bold 28px var(--font-mono);
            }

            input {
                width: 100%;
                height: 38px;
                padding-left: 14px;
                border-radius: 6px;
                font: normal 14px var(--font-mono);
                border: 1px solid var(--border-color);
            }

            .login-button {
                width: 100%;
                height: 38px;
                font: normal 14px var(--font-mono);
                background-color: var(--primary-color);
            }
        }
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

@media screen and (max-width: 1242px) {
    .header {
        .container {
            .nav-menu {
                .nav-list {
                    .nav-item {
                        display: none;

                        &:first-child {
                            display: flex !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 64em) {
    .main {
        .section {

            .media-block {
                height: 60vh !important;
            }
        }
    }
}

@media screen and (max-width: 40em) {

    .header {
        .container {
            .flex {
                .logo {
                    max-width: 120px !important;
                }

                .nav-menu {
                    .nav-list {
                        gap: 0px !important;
                    }
                }
            }
        }
    }

    .main {
        .section {
            // flex-direction: column;

            .media-block {
                height: 50vh;
            }
        }
    }

    .product-card {
        grid-column: span 12 !important;
    }

    .newsletter {
        .newsletters-form {
            width: 100% !important;
        }
    }
}