.app {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: light-dark(var(--color-white-fa), var(--color-black-18));
    // background-color: var(--aesthetic-primary-background);
}

.app.no-scroll {
    position: fixed !important;
}

@import url(./global.css);

@import url(./fonts.css);

@import url(./buttons.css);

@import url(./pages.css);

@import url(./responsive.css);