.top-bar {
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 30px;
  display: flex;
  color: white;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black-1b);
}
.top-bar .flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
}
.top-bar .flex .message {
  opacity: 0;
  height: 100%;
  width: 400px;
  display: flex;
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  text-wrap: nowrap;
  align-items: center;
  letter-spacing: 0.8px;
  min-width: -moz-max-content;
  min-width: max-content;
  justify-content: center;
  color: var(--inactive-color);
  transition: var(--transition);
  font: normal 11px var(--font-hand);
}
.top-bar .flex .message:hover {
  color: var(--color-white-ff);
}
.top-bar .flex .message.show {
  opacity: 1;
  visibility: visible;
}
.top-bar .flex a {
  text-decoration: none;
  color: var(--inactive-color);
}
.top-bar .flex a:hover {
  text-decoration: underline;
  color: var(--color-white);
}
.top-bar .flex:nth-child(1) {
  justify-content: flex-start;
}
.top-bar .flex:nth-child(2) {
  justify-content: center;
}
.top-bar .flex:nth-child(3) {
  justify-content: flex-end;
}

.header {
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--aesthetic-secondary-background);
}
.header .flex {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}
.header .flex .nav-menu {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .flex .nav-menu .nav-list {
  margin: 0px;
  padding: 0px;
  height: 100%;
  display: flex;
  list-style: none;
  align-items: center;
}
.header .flex .nav-menu .nav-list .line {
  width: 1px;
  height: 100%;
  background-color: var(--border-color);
}
.header .flex .nav-menu .nav-list .nav-item {
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.header .flex .nav-menu .nav-list .nav-item .nav-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header .flex .nav-menu .nav-list .nav-item .nav-wrapper .nav-link {
  gap: 5px;
  height: 100%;
  display: flex;
  margin-right: 20px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: light-dark(black, white);
  font: normal 14px var(--font-hand);
  color: var(--aesthetic-primary-text);
}
.header .flex .nav-menu .nav-list .nav-item .nav-sub-list {
  left: 0;
  top: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  display: flex;
  list-style: none;
  position: absolute;
  visibility: hidden;
  flex-direction: column;
  transition: var(--transition);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.header .flex .nav-menu .nav-list .nav-item .nav-sub-list li {
  width: 120px;
  min-width: 120px;
  height: 40px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
.header .flex .nav-menu .nav-list .nav-item .nav-sub-list li a {
  width: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  justify-content: space-between;
  font: normal 12px var(--font-hand);
  color: var(--aesthetic-secondary-text);
}
.header .flex .nav-menu .nav-list .nav-item .nav-sub-list li a span {
  font: normal 12px var(--font-mono-code);
}
.header .flex .nav-menu .nav-list .nav-item .nav-sub-list li:last-child {
  border: none;
}
.header .flex .nav-menu .nav-list .nav-item .nav-sub-list.show {
  opacity: 1;
  visibility: visible;
}
.header .flex .nav-menu .nav-list .nav-item:hover .nav-sub-list {
  opacity: 1;
  visibility: visible;
}
.header .flex .nav-menu .nav-list .nav-item:first-child {
  display: none;
}
.header .flex:nth-child(1) {
  justify-content: flex-start;
}
.header .flex:nth-child(2) {
  justify-content: center;
}
.header .flex:nth-child(3) {
  justify-content: flex-end;
}

.menu {
  top: 0;
  left: -340px;
  z-index: 12;
  width: 300px;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  transition: var(--transition);
  border-right: 1px solid var(--border-color);
  background-color: var(--aesthetic-secondary-background);
}
.menu .menu-header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  letter-spacing: 0.8px;
  font: bold 14px var(--font-hand);
  border-bottom: 1px solid var(--border-color);
}
.menu .menu-header span {
  width: 100%;
  padding: 0px 50px;
  justify-content: space-between;
}
.menu .menu-content {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
  justify-content: center;
}
.menu .menu-content .nav-menu {
  width: 100%;
  display: flex;
}
.menu .menu-content .nav-menu .nav-list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  list-style: none;
  align-items: center;
  flex-direction: column;
}
.menu .menu-content .nav-menu .nav-list .nav-item {
  width: 100%;
  display: flex;
  min-height: 60px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-wrapper {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-wrapper .nav-link {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  min-height: 60px;
  max-height: 60px;
  overflow: hidden;
  text-indent: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  font: normal 14px var(--font-hand);
  color: var(--aesthetic-tertiary-text);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-wrapper .nav-link:hover, .menu .menu-content .nav-menu .nav-list .nav-item .nav-wrapper .nav-link:focus-visible {
  outline: none;
  text-decoration: underline;
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-wrapper .icon-button {
  right: 50px;
  position: absolute;
  color: var(--aesthetic-tertiary-text);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-wrapper .icon-button:focus-visible {
  outline: none;
  border: 1px dashed var(--border-color);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list {
  top: 100px;
  margin: 0;
  padding: 0;
  width: 100%;
  display: none;
  list-style: none;
  flex-direction: column;
  transition: var(--transition);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item .nav-sub-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-indent: 65px;
  text-indent: 50px;
  font: normal 14px var(--font-hand);
  color: var(--aesthetic-secondary-text);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item .nav-sub-link .nav-sub-count {
  right: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  text-indent: 0px;
  overflow: hidden;
  text-align: center;
  position: absolute;
  align-items: center;
  justify-content: center;
  font: normal 12px var(--font-mono-code);
  font-family: var(--font-space-mono);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item .nav-sub-link:hover, .menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item .nav-sub-link:focus-visible {
  outline: none;
  text-decoration: underline;
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item .nav-sub-link:hover .nav-sub-count, .menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item .nav-sub-link:focus-visible .nav-sub-count {
  text-decoration: underline;
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item:first-child {
  border-top: 1px solid var(--border-color);
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list .nav-sub-item:last-child {
  border-bottom: none;
}
.menu .menu-content .nav-menu .nav-list .nav-item .nav-sub-list.show {
  display: flex;
  overflow: hidden;
}
.menu.show {
  left: 0;
}

.bag {
  top: 0px;
  right: -380px;
  z-index: 12;
  width: 320px;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  transition: var(--transition);
  border-left: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.bag .bag-header {
  width: calc(100% - 0px);
  height: 80px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  justify-content: center;
  font: bold 14px var(--font-hand);
  border-bottom: 1px solid var(--border-color);
}
.bag .bag-header span {
  min-width: -moz-max-content;
  min-width: max-content;
}
.bag .bag-content {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.bag .bag-content .bag-main {
  width: 100%;
  height: calc(100vh - 80px - 138px);
  display: flex;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.bag .bag-content .bag-main .promo {
  top: 0;
  gap: 10px;
  z-index: 2;
  width: 100%;
  display: flex;
  position: sticky;
  padding: 10px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
.bag .bag-content .bag-main .promo .promo-text {
  width: calc(100% - 40px);
  text-align: center;
  text-wrap: balance;
  letter-spacing: 1px;
  font: normal 12px var(--font-hand);
}
.bag .bag-content .bag-main .promo input {
  margin: 0;
  padding: 0;
  width: calc(100% - 40px);
  accent-color: var(--primary-color);
  color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.bag .bag-content .product-item {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 90px;
  padding: 10px 0px;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--border-color);
}
.bag .bag-content .product-item .product-item-wrapper {
  display: flex;
  align-items: flex-start;
  width: calc(100% - 40px);
  justify-content: flex-start;
}
.bag .bag-content .product-item .product-item-wrapper .product-item-picture {
  width: 90px;
  height: 90px;
  display: flex;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--border-color);
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.bag .bag-content .product-item .product-item-wrapper .product-item-picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  filter: drop-shadow(2px 2px 4px #4d4d4d);
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details {
  gap: 8px;
  width: calc(100% - 90px - 14px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-title {
  width: 100%;
  overflow: hidden;
  text-wrap: nowrap;
  letter-spacing: 1;
  text-overflow: ellipsis;
  font: normal 14px var(--font-hand);
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-price {
  display: flex;
  flex-wrap: nowrap;
  text-transform: uppercase;
  font: normal 12px var(--font-mono-code);
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-control {
  gap: 4px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-start;
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-control .icon-button {
  border-color: var(--border-color);
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-control .icon-button:last-child {
  margin-left: 20px;
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-control .icon-button:last-child:hover, .bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-control .icon-button:last-child:active {
  color: firebrick;
}
.bag .bag-content .product-item .product-item-wrapper .product-item-details .product-item-control .screen {
  width: 28px;
  height: 28px;
  display: flex;
  cursor: default;
  overflow: hidden;
  align-items: center;
  box-sizing: content-box;
  justify-content: center;
  border: 1px solid var(--border-color);
  font: normal 12px var(--font-mono-code);
}
.bag .bag-content .bag-control {
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 30px 0px;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.bag .bag-content .bag-control .wrapper {
  flex-direction: column;
  width: calc(100% - 40px);
}
.bag .bag-content .bag-control .subtotal {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  font: bold 14px var(--font-mono-code);
}
.bag .bag-content .bag-control .primary-button {
  width: 100%;
  justify-content: space-evenly;
  color: light-dark(white, black);
  background-color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.bag.show {
  right: 0px;
}

.main {
  gap: 40px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
  padding-bottom: 100px;
}
.main .banner {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-top: -40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/public/banner-tour-i.png);
}
.main .section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main .section .container {
  align-items: center;
  flex-direction: column;
}
.main .section .media-block {
  width: 100%;
  height: 92vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}
.main .section .media-block a {
  inset: 0;
  z-index: 3;
  opacity: 0;
  position: absolute;
}
.main .section .media-block picture {
  z-index: 1;
  inset: 0px;
  position: absolute;
}
.main .section .media-block picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
}
.main .section .media-block h2 {
  z-index: 2;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font: normal 30px var(--font-hand);
}
.main .section .media-block:hover {
  filter: brightness(0.4);
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .container {
  padding: 40px 0px;
  flex-direction: column;
  border-top: 1px solid var(--border-color);
}
.footer .container .newsletter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.footer .container .newsletter .newsletters-form {
  width: 500px;
  display: flex;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 32px;
  flex-direction: column;
}
.footer .container .newsletter .newsletters-form .form-block {
  width: 100%;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer .container .newsletter .newsletters-form #block-3,
.footer .container .newsletter .newsletters-form #block-5 {
  margin-bottom: -10px;
}
.footer .container .newsletter .newsletters-form .title {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
  text-wrap: balance;
  margin-bottom: 14px;
  pointer-events: none;
  letter-spacing: 0.8px;
  font: normal 22px var(--font-hand);
}
.footer .container .newsletter .newsletters-form .input,
.footer .container .newsletter .newsletters-form select {
  width: 100%;
  height: 34px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding-left: 16px;
  letter-spacing: 0.8px;
  transition: var(--transition);
  font: normal 14px var(--font-hand);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.footer .container .newsletter .newsletters-form .input:focus-visible,
.footer .container .newsletter .newsletters-form select:focus-visible {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0px 0px 8px var(--primary-color);
}
.footer .container .newsletter .newsletters-form .input:hover,
.footer .container .newsletter .newsletters-form select:hover {
  border-color: light-dark(var(--color-black-20), var(--color-white-99));
}
.footer .container .newsletter .newsletters-form .primary-button {
  background-color: var(--color-black-12) !important;
}
.footer .container .newsletter .newsletters-form .policy {
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
  text-wrap: balance;
  letter-spacing: 0.8px;
  font: normal 11px var(--font-hand);
}
.footer .container .newsletter .newsletters-form .policy a {
  text-decoration: underline;
}
.footer .container .newsletter .newsletters-form .form-alert {
  color: white;
  padding: 1px 8px;
  border-radius: 4px;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: firebrick;
  font: normal 11px var(--font-hand);
}
.footer .container .newsletter .newsletters-form .alert {
  width: 100%;
  height: 18px;
  display: flex;
  color: white;
  cursor: default;
  overflow: hidden;
  text-indent: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: relative;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 0.4px;
  text-overflow: ellipsis;
  justify-content: center;
  line-height: 18px !important;
  background-color: firebrick;
  font: normal 10px var(--font-hand);
}
.footer .container .copyright {
  gap: 4px;
  width: 100%;
  display: flex;
  padding-top: 30px;
  align-items: center;
  justify-content: center;
  font: normal 10px var(--font-hand);
  letter-spacing: 1px;
}

.login {
  top: 200px;
  opacity: 0;
  z-index: 20;
  width: 400px;
  display: flex;
  position: fixed;
  visibility: hidden;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  transition: var(--transition);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.login .form {
  padding: 20px 0px;
  width: calc(100% - 40px);
}
.login .form .block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.login .form .block .title {
  margin: 10px 0px;
  font: bold 28px var(--font-mono);
}
.login .form .block input {
  width: 100%;
  height: 38px;
  padding-left: 14px;
  border-radius: 6px;
  font: normal 14px var(--font-mono);
  border: 1px solid var(--border-color);
}
.login .form .block .login-button {
  width: 100%;
  height: 38px;
  font: normal 14px var(--font-mono);
  background-color: var(--primary-color);
}
.login.show {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 1242px) {
  .header .container .nav-menu .nav-list .nav-item {
    display: none;
  }
  .header .container .nav-menu .nav-list .nav-item:first-child {
    display: flex !important;
  }
}
@media screen and (max-width: 64em) {
  .main .section .media-block {
    height: 60vh !important;
  }
}
@media screen and (max-width: 40em) {
  .header .container .flex .logo {
    max-width: 120px !important;
  }
  .header .container .flex .nav-menu .nav-list {
    gap: 0px !important;
  }
  .main .section .media-block {
    height: 50vh;
  }
  .product-card {
    grid-column: span 12 !important;
  }
  .newsletter .newsletters-form {
    width: 100% !important;
  }
}/*# sourceMappingURL=layout.css.map */